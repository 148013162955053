export const inverstmentGoals = [
    {
        id: 1,
        value: 'Preserve_my_savings'
    },
    {
        id: 2,
        value: 'Growth'
    },
    {
        id: 3,
        value: 'A_source_of_income'
    },
    {
        id: 4,
        value: 'Speculation'
    },
    {
        id: 5,
        value: 'Something_else'
    }]
export const inverstmentDroped = [
    {
        id: 1,
        value: 'sell_All'
    },
    {
        id: 2,
        value: 'sell_Partially'
    },
    {
        id: 3,
        value: 'save_All'
    },
    {
        id: 4,
        value: 'buy_Again'
    },]

    export const profitType = [
        {
            id: 1,
            value: 'stable_growth'
        },
        {
            id: 2,
            value: 'great_dynamics'
        },
        {
            id: 3,
            value: 'risk'
        }]

        export const riskRewards = [
            {
                id: 1,
                value: 'on_1'
            },
            {
                id: 2,
                value: 'on_2'
            },
            {
                id: 3,
                value: 'on_3'
            },
            {
                id: 4,
                value: 'on_4'
            },
            {
                id: 5,
                value: 'on_5'
            }]

            export const transactionType = [
                {
                    id: 1,
                    value: 'credit'
                },
                {
                    id: 2,
                    value: 'debit'
                },
                ]
                export const modeofPay = [
                    {
                        id: 1,
                        value: 'cash'
                    },
                    {
                        id: 2,
                        value: 'cheque'
                    },
                    {
                        id: 3,
                        value: 'wired'
                    },
                    {
                        id: 4,
                        value: 'online_pay'
                    },
    ]

        export const profitreason = [
            {
                id: 1,
                value: 'longterm'
            },
            {
                id: 2,
                value: 'midbonus'
            },
            {
                id: 3,
                value: 'welcomebonus'
            },
]         




