import {
  AppBar,
  Avatar,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { Box } from '@mui/system';
import MemberDetails from './MemberDetails';
import CreateUser from './CreateUser';
import CreateTransaction from './CreateTransaction';
import CreateProfit from './CreateProfit';
import Buttons from './Buttons';
import CreateDailyData from './CreateDailyData';
import UpdateBalance from './UpdateBalance';
export default function AdminPanel() {
  const [drawer, setDrawer] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [screenComponent, setScreenComponent] = useState('buttonComponent');
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('userToken') === null) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('userToken') === null) {
      navigate('/');
    }
  }, [localStorage.getItem('userToken')]);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = (screenToShow) => {
    setScreenComponent(screenToShow);
    setAnchorElNav(null);
  };

  const renderMobile = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ background: ' linear-gradient(to right, #5c258d, #4389a2)' }}
      >
        <Grid item alignItems="center">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawer(true)}
            sx={{ cursor: 'pointer',mr: 2  }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };
  const renderWeb = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          item
          display={'flex'}
          justifyContent={'start'}
          xs={1}
          sm={1}
          md={1}
          lg={1}
        >
          <Link to="#">
            <img
              src="/assets/images/TradeTogether_Logo.png"
              style={{width:'50px !important',height:'50px'}}
              alt="header logo"
            />
          </Link>
        </Grid>
        <Grid
          item
          display={'flex'}
          justifyContent={'center'}
          xs={8}
          sm={8}
          md={8}
          lg={8}
        >
          <Typography
            textAlign="center"
            sx={screenComponent==='memberDetails'?{ pr: 2, cursor: 'pointer',color:'#00ff3bcf' }:{ pr: 2, cursor: 'pointer', }}
            onClick={() => setScreenComponent('memberDetails')}
          >
            MEMBER DETAILS
          </Typography>
          <Typography
            textAlign="center"
            sx={screenComponent==='member'?{ pr: 2, cursor: 'pointer',color:'#00ff3bcf' }:{ pr: 2, cursor: 'pointer', }}
            onClick={() => setScreenComponent('member')}
          >
            CREATE NEW MEMBER
          </Typography>
          <Typography
            textAlign="center"
            sx={screenComponent==='profit'?{ pr: 2, cursor: 'pointer',color:'#00ff3bcf' }:{ pr: 2, cursor: 'pointer', }}
            onClick={() => setScreenComponent('profit')}
          >
            CREATE ENTRY
          </Typography>
          <Typography
            textAlign="center"
            sx={screenComponent==='transaction'?{ pr: 2, cursor: 'pointer',color:'#00ff3bcf' }:{ pr: 2, cursor: 'pointer', }}
            onClick={() => setScreenComponent('transaction')}
          >
            CREATE TRANSACTION
          </Typography>
          <Typography
            textAlign="center"
            sx={screenComponent==='daily-data'?{ pr: 2, cursor: 'pointer',color:'#00ff3bcf' }:{ pr: 2, cursor: 'pointer', }}
            onClick={() => setScreenComponent('daily-data')}
          >
            CREATE DAILY DATA
          </Typography>
          <Typography
            textAlign="center"
            sx={screenComponent==='update-balance'?{ pr: 2, cursor: 'pointer',color:'#00ff3bcf' }:{ pr: 2, cursor: 'pointer', }}
            onClick={() => setScreenComponent('update-balance')}
          >
            UPDATE BALANCE
          </Typography>
        </Grid>
        <Grid
          item
          alignItems="right"
          display={'flex'}
          justifyContent={'end'}
          xs={1}
          sm={1}
          md={1}
          lg={1}
        >
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Profile">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key={'logout'} onClick={handleCloseUserMenu}>
                <Typography
                  textAlign="center"
                  onClick={() => localStorage.removeItem('userToken')}
                >
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid>
    );
  };
  const getComponent = () => {
    let newComponent;
    if (screenComponent === 'memberDetails') {
      newComponent = <MemberDetails />;
    } else if (screenComponent === 'member') {
      newComponent = <CreateUser />;
    } else if (screenComponent === 'transaction') {
      newComponent = <CreateTransaction />;
    } else if (screenComponent === 'profit') {
      newComponent = <CreateProfit />;
    } else if (screenComponent === 'daily-data') {
      newComponent = <CreateDailyData />;
    }else if (screenComponent === 'update-balance') {
      newComponent = <UpdateBalance />;
    }else if (screenComponent === 'buttonComponent') {
        newComponent = <Buttons propFunc={getScreenComponent}/>;
    }
    return newComponent;
  };

  const getScreenComponent=(value)=>{
    setScreenComponent(value)
  }
  const closeMobileDrawer = (screenToShow) => {
    setScreenComponent(screenToShow);
    setDrawer(false);
  };
  return (
    <>
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: '#282c34' }}>
          {isMobile ? renderMobile() : renderWeb()}
        </Toolbar>
        <Drawer anchor="left" open={drawer} onClose={() => setDrawer(false)}>
          <List>
            <ListItem
              button
              key="new member"
              onClick={() => closeMobileDrawer('member')}
            >
              Create New Member
            </ListItem>
            <ListItem
              button
              key="neW transaction"
              onClick={() => closeMobileDrawer('transaction')}
            >
              Create New Transaction
            </ListItem>
            <ListItem
              button
              key="new profit"
              onClick={() => closeMobileDrawer('profit')}
            >
              Create New Profit
            </ListItem>
            <ListItem
              button
              key="view Details"
              onClick={() => closeMobileDrawer('memberDetails')}
            >
              View Member Details
            </ListItem>
            <ListItem
              button
              key="view Details"
              onClick={() => closeMobileDrawer('daily-data')}
            >
              Create Daily Data
            </ListItem>
            <ListItem
              button
              key="view Details"
              onClick={() => closeMobileDrawer('update-balance')}
            >
             Update Balance
            </ListItem>
          </List>
        </Drawer>
      </AppBar>
      {getComponent()}
    </>
  );
}
