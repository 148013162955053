import React from 'react'
import { BrowserRouter, Route, Routes, } from 'react-router-dom'
import AdminPanel from '../components/pages/admin'
import Login from '../components/pages/login/Login'

export default function AppRouting() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/"  element={<Login />} />
                <Route path="/home"  element={<AdminPanel />} />
            </Routes>
        </BrowserRouter>
    )
}
