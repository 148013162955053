import React, { useState, useEffect } from 'react'
import { Alert, Button, Grid, MenuItem, Select, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import { modeofPay, transactionType } from '../admin/Utils'

export default function TransactionUpdate(props) {
    const [id, setID] = useState()
    const [transactionData, setTransactionData] = useState({ id: 0, amount: 0, mode_of_pay: '', type: '',defaultValues:{},userId:0})
    const [createError, setError] = useState(false);
    const [errorMessaage, setErrorMessage] = useState(null);
    const [errorColor, setErrorColor] = useState('');
    const handleChange = (event, newValue) => {
        setID(event.target.value)
        props.datas.forEach(element => {
            if (element.id === event.target.value) {
                setTransactionData({ userId:props.userId,defaultValues:element,id: element.id, amount: element.amount, mode_of_pay: element.mode_of_pay, type: element.type })
            }
        });
    }
    const handleDailyDataChange = (e) => {
        var key = e.target.name;
        setTransactionData({ ...transactionData, [key]: e.target.value })
    }
    const handleSubmit = () => {
        let token = localStorage.getItem('userToken')
        const confiq = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        let confirmUpdate = false;

        props.datas.forEach(element => {
            if (element.id === transactionData.id) {

                if (transactionData.amount !== element.amount || transactionData.mode_of_pay !== element.mode_of_pay || transactionData.type !== element.type) {
                    confirmUpdate = true;
                }
            }
        });

        if (confirmUpdate) {
            axios.post(`${process.env.REACT_APP_API_URL}customers/update_transaction`, transactionData, confiq)
                .then(function (response) {
                  console.log();
                  if(response.data.MESSAGE==='Unableto process'){
                    setError(true)
                    setErrorColor('error')
                    setErrorMessage('Unable to process your request')
                    setTimeout(() => {
                        props.removeUpdate()
                    }, 3000);
                  }else{
                    setError(true)
                    setErrorColor('success')
                    setErrorMessage('Transaction Data updated')
                    setTimeout(() => {
                        props.removeUpdate()
                    }, 3000);
                  }
                       
                    
                })
                .catch(function (error) {
                    setError(true)
                    setErrorColor('error')
                    setErrorMessage('Something went wrong')
                });
        } else {
            setError(true)
            setErrorColor('error')
            setErrorMessage('Already uptodate')
        }

    }
    return (
        <Grid container sx={{ mt: 2 }}>
            <Snackbar
                open={createError}
                autoHideDuration={6000}
                onClose={() => setError(false)}
                message={errorMessaage}
            >
                <Alert
                    onClose={() => setError(false)}
                    severity={
                        errorColor
                    }
                    sx={{ width: '100%' }}
                >
                    {errorMessaage}
                </Alert>
            </Snackbar>
            <Grid item xs={12}>
                <label>Select Date<br /><br /></label>
                <Select
                    sx={{ minWidth: 300 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={id}
                    onChange={handleChange}
                >
                    {props.datas.map((data) => (
                        <MenuItem value={data.id}>{moment(new Date(data.transaction_date)).format("DD/MM/YYYY")}</MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={3} sx={{ mt: 2 }}>
                <TextField id="amount"
                    value={transactionData.amount}
                    onChange={(e) => handleDailyDataChange(e)}
                    name="amount"
                    label="Amount"
                    variant="outlined" />
            </Grid>
            <Grid item xs={3} sx={{ mt: 2 }}>
                <TextField
                    select
                    sx={{ width: 300 }}
                    id="mode_of_pay"
                    name="mode_of_pay"
                    value={transactionData.mode_of_pay}
                    onChange={(e) => handleDailyDataChange(e)}
                    label="Payment Type"
                    variant="outlined">
                    {modeofPay.map((name) => (
                        <MenuItem
                            key={name.id}
                            value={name.value}

                        >
                            {name.value}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={3} sx={{ mt: 2 }}>
                <TextField
                    select
                    sx={{ width: 300 }}
                    id="type"
                    name="type"
                    value={transactionData.type}
                    onChange={(e) => handleDailyDataChange(e)}
                    label="Transaction Type" variant="outlined">
                    {transactionType.map((name) => (
                        <MenuItem
                            key={name.id}
                            value={name.value}

                        >
                            {name.value}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={3} sx={{ mt: 2 }}></Grid>
            <Grid item xs={9} sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Button variant='contained' onClick={() => handleSubmit()}>Save</Button>
            </Grid>
            <Grid item xs={9} sx={{ mt: 2 }}></Grid>
        </Grid>
    )
}
