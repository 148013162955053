import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({
    loginBgimage:{
        // backgroundImage: `url(../../assets/images/FFF_TradeTogether_LO 1.png)`,
        // backgroundRepeat: 'no-repeat',
        // backgroundColor: (t) =>
        //   t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
      }
  });

  export default useStyles;