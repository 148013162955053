import React from 'react'
import DailyDataUpdate from './DailyDataUpdate';
import ProfitUpdate from './ProfitUpdate';
import TransactionUpdate from './TransactionUpdate';

export default function UpdateData(props) {

    const getUpdateComponent = (activity,data) => {
        if (activity === 'transactions') {
            return <TransactionUpdate datas={data} removeUpdate={props.removeUpdate} userId={props.userId} />
        } else if (activity === 'profits') {
            return <ProfitUpdate datas={data} removeUpdate={props.removeUpdate} userId={props.userId}/>
        } else if (activity === 'daily-datas') {
            return <DailyDataUpdate datas={data} removeUpdate={props.removeUpdate}/>
        }
    }
    return (
        <div>
            {getUpdateComponent(props.activity,props.datas)}</div>
    )
}
