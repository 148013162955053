
import { Alert, Box, Button, Grid, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import { isMobile } from 'react-device-detect';
import { modeofPay, profitreason, transactionType } from './Utils';
import axios from 'axios';
import CommonTable from './CommonTable';
export default function CreateProfit() {
  const [dob, setDOB] = useState(null);
  const [dropdowndatas, setDropdownValues] = useState({});
  const [userData, setUserData] = useState([])
  const [createError, setError] = useState(false);
  const [errorMessaage, setErrorMessage] = useState(null);
  const [profitData,setProfitData]=useState([])
  const profitColums = [
    { id: 'customer_id', label: 'Id', minWidth: 170 },
    { id: 'amount', label: 'Profit', minWidth: 100 },
    { id: 'reason', label: 'Reason', minWidth: 100 },
    { id: 'balance_on_date', label: 'Acc Bal', minWidth: 100 },
    { id: 'added_on', label: 'Date', minWidth: 100 },

  ];
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console

    const regdata = {
      data: {
        customer: data.get('customer'),
        added_on: data.get('added_on'),
        amount: data.get('amount'),
        balance_on_date: 0,
        reason: data.get('reason'),
      }
    }
    let token = localStorage.getItem('userToken')
    const confiq = {
      headers: {
        Authorization: `Bearer ${token} `
      }
    }
    axios.post(`${process.env.REACT_APP_API_URL}profits`, regdata, confiq)
      .then(function (response) {
        if(response.data){
          setError(true)
          setErrorMessage('Profit Added Successfully')
          document.forms['createForm'].reset();
          setDropdownValues({})
        }
      })
      .catch(function (error) {
        setError(true)
        setErrorMessage('Something Went Wrong')
      });

  };

  useEffect(() => {
    let token = localStorage.getItem('userToken')
    const confiq = {
      headers: {
        Authorization: `Bearer ${token} `
      }
    }
    axios.get(`${process.env.REACT_APP_API_URL}customers/user_details`, confiq)
      .then(function (response) {
        setUserData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  const handleDropDownChange = (e) => {
    const key = e.target.name
    setDropdownValues({ ...dropdowndatas, [key]: e.target.value });
    if(key==='customer'){
      getProfitData(e.target.value)
    }
   
  }

  const getProfitData=(id)=>{
    let token = localStorage.getItem('userToken');
    const confiq = {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };
    const bodyParameters = {
      key: 'value',
    };
    axios
    .post(
      `${process.env.REACT_APP_API_URL}customers/userTransaction?id=${id}&type=profits`,
      bodyParameters,
      confiq
    )
    .then(function (response) {
      if (response.data.length > 0) {
        setProfitData(response.data);
      } else {
        setProfitData([]);
        setError(true);
        setErrorMessage('No rows available!');
      }
    })
    .catch(function (error) {
      setError(true);
      setErrorMessage('Something Went Wrong');
    });
  }
  return (
    <Grid container component="main" sx={!isMobile ? { height: '100vh', pl: 50, pr: 50, pt: 10, pb: 10, width: '100%', } : { height: "100vh" }}>
        <Snackbar
        open={createError}
        autoHideDuration={6000}
        onClose={() => setError(false)}
        message={errorMessaage}
      
      >
        <Alert
          onClose={() => setError(false)}
          severity={errorMessaage==='Profit Added Successfully'?'success':'error'}
          sx={{ width: '100%' }}
        >
          {errorMessaage}
        </Alert>
      </Snackbar>
      <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='center' component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 6,
            mx: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container component="form"   name='createForm' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 1 }}><Typography variant='h5'>Create Entry</Typography></Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
              <TextField
                select
                label='Customer Id'
                name='customer'
                id="customer"
                value={dropdowndatas.customer}
                onChange={(e) => handleDropDownChange(e)}
                fullWidth
              >
                {userData.map((name) => (
                  <MenuItem
                    key={name.id}
                    value={name.id}

                  >
                    {name.email}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1, mt: 0 }}>

              <TextField
                margin="normal"
                required
                fullWidth
                name="added_on"
                label=""
                type="date"
                id="added_on"
                autoComplete='off'
                sx={{mt:'0px'}}
              />

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="amount"
                label="Amount"
                name="amount"
                autoComplete="off"
                type="text"
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="balance_on_date"
                label="Customer Balance"
                name="balance_on_date"
                autoComplete="off"
                autoFocus
                type="text"
              />
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
              <TextField
                select
                label='Transaction Type'
                name='reason'
                id="reason"
                value={dropdowndatas.reason}
                onChange={(e) => handleDropDownChange(e)}
                fullWidth
                sx={{mt:2}}
              >
                {profitreason.map((name) => (
                  <MenuItem
                    key={name.id}
                    value={name.value}

                  >
                    {name.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
            </Grid> */}


            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
              <Button
                type="reset"
                fullWidth
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => setDropdownValues({})}
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Create
              </Button>
            </Grid>

          </Grid>
         {profitData.length>0?<CommonTable columnName={profitColums} data={profitData}/>:''}
        </Box>
      </Grid>
    </Grid>
  )
}
