import React, { useState, useEffect } from 'react'
import { Alert, Button, Grid, MenuItem, Select, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import moment from 'moment'

export default function ProfitUpdate(props) {
    const [id, setID] = useState()
    const [profitData, setProfitData] = useState({ profit_id: 0, amount: 0, reason: '',userId:0,defaultValues:{} })
    const [createError, setError] = useState(false);
    const [errorMessaage, setErrorMessage] = useState(null);
    const [errorColor, setErrorColor] = useState('');
    const handleChange = (event, newValue) => {
        setID(event.target.value)
        props.datas.forEach(element => {
            if (element.profit_id === event.target.value) {
                setProfitData({defaultValues:element,userId:props.userId, profit_id: element.profit_id, amount: element.amount, reason: element.reason })
            }
        });
    }
    const handleDailyDataChange = (e) => {
        var key = e.target.name;
        setProfitData({ ...profitData, [key]: e.target.value })
    }
    const handleSubmit = () => {

        let token = localStorage.getItem('userToken')
        const confiq = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        let confirmUpdate = false;

        props.datas.forEach(element => {
            if (element.id === profitData.profit_id) {

                if (profitData.amount !== element.amount || profitData.reason !== element.reason) {
                    confirmUpdate = true;
                }
            }
        });

        if (confirmUpdate) {
            axios.post(`${process.env.REACT_APP_API_URL}customers/update_profit`, profitData, confiq)
                .then(function (response) {

        
                        setError(true)
                        setErrorColor('success')
                        setErrorMessage('Daily Data updated')
                        setTimeout(() => {
                            props.removeUpdate()
                        }, 3000);
                  

                })
                .catch(function (error) {
                    setError(true)
                    setErrorColor('success')
                    setErrorMessage('Profit Data updated')
                    setTimeout(() => {
                        props.removeUpdate()
                    }, 3000);
                });
        } else {
            setError(true)
            setErrorColor('success')
            setErrorMessage('Already uptodate')
         
        }

    }
    return (
        <Grid container sx={{ mt: 2 }}>
             <Snackbar
                open={createError}
                autoHideDuration={6000}
                onClose={() => setError(false)}
                message={errorMessaage}
            >
                <Alert
                    onClose={() => setError(false)}
                    severity={
                       errorColor
                    }
                    sx={{ width: '100%' }}
                >
                    {errorMessaage}
                </Alert>
            </Snackbar>
            <Grid item xs={12}>
                <label>Select Date<br /><br /></label>
                <Select
                    sx={{ minWidth: 300 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={id}
                    onChange={handleChange}
                >
                    {props.datas.map((data) => (
                        <MenuItem value={data.profit_id}>{moment(new Date(data.added_on)).format("DD/MM/YYYY")}</MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={3} sx={{ mt: 2 }}>
                <TextField id="amount" value={profitData.amount} onChange={(e) => handleDailyDataChange(e)} name="amount" label="Profit" variant="outlined" />
            </Grid>
            <Grid item xs={3} sx={{ mt: 2 }}>
                <TextField id="reason" name="reason" value={profitData.reason} onChange={(e) => handleDailyDataChange(e)} label="Reason" variant="outlined" />
            </Grid>
            <Grid item xs={3} sx={{ mt: 2 }}>
                {/* <TextField id="percentage" name="percentage" value={dailyData.percentage} onChange={(e) => handleDailyDataChange(e)} label="Profit %" variant="outlined" /> */}
            </Grid>
            <Grid item xs={3} sx={{ mt: 2 }}></Grid>
            <Grid item xs={9} sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Button variant='contained' onClick={() => handleSubmit()}>Save</Button>
            </Grid>
            <Grid item xs={9} sx={{ mt: 2 }}></Grid>
        </Grid>
    )
}
