import './App.css';
import AppRouting from './routing';

function App() {
  return (
   <AppRouting/>
  );
}

export default App;
