import  React,{useEffect,useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import bgimage from '../../assets/images/FFF_TradeTogether_LO 1.png';
import useStyles from './styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const theme = createTheme();

export default function Login() {
  const classes=useStyles;
  const navigate = useNavigate();
const [loginError,setLoginError]=useState(false)
const [errorMessaage,setErrorMessage]=useState(null)
  useEffect(() => {
    if(localStorage.getItem('userToken')!=null){
      navigate("/home")
    }
  }, [])
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const loginData={
      identifier: data.get('email'),
      password: data.get('password'),
    }
    if(loginData.identifier!=''&&loginData.password!=''){
    axios.post(`${process.env.REACT_APP_API_URL}auth/local`, loginData)
    .then(function (response) {
        if(response){
          localStorage.setItem('userToken',response.data.jwt)
          navigate("/home")
        }
    })
    .catch(function (error) {
       if(error){
        setLoginError(true)
        setErrorMessage("something went wrong")
       }
    });
  }else{
    setLoginError(true)
    setErrorMessage("Username/Password Required!")
  }
  };

  return (
<ThemeProvider theme={theme} sx={{    }}>
<Snackbar
        open={loginError}
        autoHideDuration={6000}
        onClose={()=>setLoginError(false)}
        message={errorMessaage}
        severity="error"
      ><Alert  onClose={()=>setLoginError(false)} severity="error" sx={{ width: '100%' }}>
   {errorMessaage}
    </Alert></Snackbar>

      <Grid container component="main" sx={!isMobile?{ height: '100vh',pl:50,pr:50,pt:20,pb:20,width:'100%',
    background:" linear-gradient(to right, #5c258d, #4389a2)" }:{height:"100vh"}}>
        <CssBaseline />
        {/* <Grid
          item
          xs={false}
          xs={0}
          sm={0}
          md={0}
          lg={0}
        ><img src='../assets/images/TradeTogether_Logo.png'/></Grid> */}
           {/* sx={{
            backgroundImage: `url(../assets/images/FFF_TradeTogether_LO 1.png)`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }} */}
        <Grid item xs={12} sm={12} md={12}  lg={12} display='flex' justifyContent='center'  component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="off"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="off"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}