import { Alert, Box, Button, Grid, MenuItem, Paper, Snackbar, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

export default function UpdateBalance() {
    const [userData, setUserData] = useState([]);
    const [dropdowndatas, setDropdownValues] = useState({});
    const [currentBalance, setCurrentBalance] = useState({ current_balance: 0, profit_earned: 0, total_invested: 0 })
    const [createError, setError] = useState(false);
    const [errorMessaage, setErrorMessage] = useState(null);
    const [errorColor, setErrorColor] = useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let token = localStorage.getItem('userToken')
        const confiq = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const bodyParameters = {
            key: "value"
        };
        const JSONData = {
            newBalance: data.get('newBalance') === '' ? currentBalance.current_balance : parseInt(data.get('newBalance')),
            newProfit: data.get('newProfit') === '' ? currentBalance.profit_earned : parseInt(data.get('newProfit')),
            newTotalInvested: data.get('newTotalInvested') === '' ? currentBalance.total_invested : parseInt(data.get('newTotalInvested'))
        }
        if (parseInt(JSONData.newBalance) && parseInt(JSONData.newProfit) && parseInt(JSONData.newTotalInvested)) {
            axios.post(`${process.env.REACT_APP_API_URL}customers/get_update_balance?type=update&id=${data.get('customer')}`, JSONData, confiq)
                .then(function (response) {
                    if (response.data.changedRows > 0) {
                        setError(true)
                        setErrorColor('success')
                        setErrorMessage('User balance updated')
                        getUserBalance(dropdowndatas.customer)
                    } else {
                        setError(true)
                        setErrorColor('warning')
                        setErrorMessage('Already Uptodate')
                    }
                })
                .catch(function (error) {
                    //error.response.status
                    setError(true)
                    setErrorColor('error')
                    setErrorMessage('Something went wrong')
                });
        } else {
            setError(true)
            setErrorColor('error')
            setErrorMessage('Invalid values')
        }

    }

    useEffect(() => {
        let token = localStorage.getItem('userToken')
        const confiq = {
            headers: {
                Authorization: `Bearer ${token} `
            }
        }
        axios.get(`${process.env.REACT_APP_API_URL}customers/user_details`, confiq)
            .then(function (response) {
                setUserData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const handleDropDownChange = (e) => {
        const key = e.target.name
        setDropdownValues({ ...dropdowndatas, [key]: e.target.value });
        getUserBalance(e.target.value)
    }
    const getUserBalance = (userId) => {
        let token = localStorage.getItem('userToken')
        const confiq = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const bodyParameters = {
            key: "value"
        };
        axios.post(`${process.env.REACT_APP_API_URL}customers/get_update_balance?type=get&id=${userId}`, bodyParameters, confiq)
            .then(function (response) {
                setCurrentBalance({
                    current_balance: response.data[0].current_balance,
                    profit_earned: response.data[0].profit_earned,
                    total_invested: response.data[0].total_invested
                })
            })
            .catch(function (error) {
                setError(true)
                setErrorColor('error')
                setErrorMessage('Something went wrong')
            });
    }
    return (
        <Grid container component="main" sx={!isMobile ? { height: '100vh', pl: 50, pr: 50, pt: 10, pb: 10, width: '100%', } : { height: "100vh" }}>
            <Snackbar
                open={createError}
                autoHideDuration={6000}
                onClose={() => setError(false)}
                message={errorMessaage}

            >
                <Alert
                    onClose={() => setError(false)}
                    severity={errorColor}
                    sx={{ width: '100%' }}
                >
                    {errorMessaage}
                </Alert>
            </Snackbar>
            <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='center' component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 6,
                        mx: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Grid container component="form" name='createForm' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 1 }}><Typography variant='h5'>Update Balance</Typography></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>                        <TextField
                            select
                            label='Customer Id'
                            name='customer'
                            id="customer"
                            value={dropdowndatas.customer}
                            onChange={(e) => handleDropDownChange(e)}
                            fullWidth
                        >
                            {userData.map((name) => (
                                <MenuItem
                                    key={name.id}
                                    value={name.id}

                                >
                                    {name.email}
                                </MenuItem>
                            ))}
                        </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{}}></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 1, pb: 0 }}><Typography variant='p'>Account Balance</Typography></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{}}>
                            <TextField
                                disabled
                                margin="normal"
                                fullWidth
                                id="currentBalance"
                                label=""
                                name="currentBalance"
                                autoComplete="off"
                                type="text"
                                value={currentBalance.current_balance.toFixed(2)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1, pb: 0, pt: 0 }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="newBalance"
                                label="New account balance"
                                name="newBalance"
                                autoComplete="off"
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 1, pb: 0 }}><Typography variant='p'>Profit Earned</Typography></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{}}>
                            <TextField
                                disabled
                                margin="normal"
                                fullWidth
                                id="currentBalance"
                                label=""
                                name="currentBalance"
                                autoComplete="off"
                                type="text"
                                value={currentBalance.profit_earned.toFixed(2)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1, pb: 0, pt: 0 }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="newProfit"
                                label="New profit value"
                                name="newProfit"
                                autoComplete="off"
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 1, pb: 0 }}><Typography variant='p'>Total Invested</Typography></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{}}>
                            <TextField
                                disabled
                                margin="normal"
                                fullWidth
                                id="totalInvested"
                                label=""
                                name="totalInvested"
                                autoComplete="off"
                                type="text"
                                value={currentBalance.total_invested.toFixed(2)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1, pb: 0, pt: 0 }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="newTotalInvested"
                                label="New invested amount"
                                name="newTotalInvested"
                                autoComplete="off"
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
                            <Button
                                type="reset"
                                fullWidth
                                variant="outlined"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={() => setDropdownValues({})}
                            >
                                Clear
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}
