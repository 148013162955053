import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React,{useState} from 'react'

export default function CommonTable(props) {
    const [columnName,setColumnName]=useState(props.columnName)



  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <TableContainer sx={{ maxHeight: 'auto' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columnName.map((column) => (
              <TableCell
                key={column.id}
                // align={column.align}
                //  style={{ minWidth: column.minWidth }}
                sx={{
                  backgroundColor: 'yellowgreen',
                  border: '1px solid black',
                  borderRight: '0px',
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                >
                  {columnName.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{
                          border: '1px solid rgba(224, 224, 224, 1)',
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
    {/* <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={userData.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    /> */}
  </Paper>
  )
}
