import {
  Alert,
  Button,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ReactCardFlip from 'react-card-flip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateProduct from './UpdateProduct';
import UpdateData from '../updateData';
import moment from 'moment'
export default function MemberDetails() {
  const [fliped, setISFlip] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userData, setUserData] = useState([]);
  const [apiReqData, setApiReqData] = useState(null);
  const [apiReqId, setApiReqId] = useState(null);
  const [userpersonalData, setUserPersonalData] = useState([]);
  const [createError, setError] = useState(false);
  const [errorMessaage, setErrorMessage] = useState(null);
  const [userDetailsColums, setUserDetailsColums] = useState(null);
  const [updateDetails, setUpdateDetails] = useState(false);
  useEffect(() => {
    let token = localStorage.getItem('userToken');
    const confiq = {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}customers/user_details`, confiq)
      .then(function (response) {
        processMemberDetails(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const processMemberDetails = (responseData) => {
    let data = {},
      arr = [];
    responseData.forEach((item) => {
      data['id'] = item.id;
      data['name'] = item.email;
      data['mobile'] = item.username;
      data['transactionDetails'] = (
        <Button onClick={() => getUserData('transactions', item.id, true)}>
          View
        </Button>
      );
      data['Profits'] = (
        <Button onClick={() => getUserData('profits', item.id, true)}>View</Button>
      );
      data['DailyRecord'] = (
        <Button onClick={() => getUserData('daily-datas', item.id, true)}>
          View
        </Button>
      );
      data['productDetails'] = (
        <Button onClick={() => getUserData('product_update', item.id, true)}>
          View
        </Button>
      );
      data['actions'] = (<>
        {/* <Button onClick={() => getUserData('daily-datas', item.id)}> */}
        <EditIcon />
        {/* </Button> */}
        {/* <Button onClick={() => getUserData('daily-datas', item.id)}> */}
        <DeleteIcon />
        {/* </Button> */}
      </>
      );
      arr.push(data);
      data = {};
    });

    setUserData(arr);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (e, id) => {

    setISFlip(!fliped);
    setUpdateDetails(false)
    // if(!fliped){
    //   if(e!='toFrontFace')
    //   {

    //     setApiReqData(e)
    //     setApiReqId(id)
    //   }else{
    //     setApiReqData(null)
    //     setApiReqId(null)
    //   }
    // }
  };
  const columns = [
    { id: 'id', label: 'Id', minWidth: 170 },
    { id: 'name', label: 'Email', minWidth: 100 },
    {
      id: 'mobile',
      label: 'User Name',
      minWidth: 170,
      align: 'left',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'transactionDetails',
      label: ' Trasactions',
      minWidth: 170,
      align: 'left',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'Profits',
      label: 'Profits',
      minWidth: 170,
      align: 'left',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'DailyRecord',
      label: 'Daily Datas',
      minWidth: 170,
      align: 'left',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'productDetails',
      label: 'Product Details',
      minWidth: 170,
      align: 'left',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 170,
      align: 'left',
      format: (value) => value.toFixed(2),
    },
  ];

  const getUserData = (apiReqData, id, flipOrNot) => {
    setApiReqId(id)
    setUserDetailsColums(apiReqData)
    let token = localStorage.getItem('userToken');
    const confiq = {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    };
    const bodyParameters = {
      key: 'value',
    };
    if (apiReqData != null) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}customers/userTransaction?id=${id}&type=${apiReqData}`,
          bodyParameters,
          confiq
        )
        .then(function (response) {
          if (response.data.length > 0) {
            if (flipOrNot) {
              setISFlip(!fliped)
            }
            setUserPersonalData(response.data);
          } else {
            setError(true);
            setErrorMessage('No rows available!');
          }
        })
        .catch(function (error) {
          setError(true);
          setErrorMessage('Something Went Wrong');
        });
    }
  };

  const profitColums = [
    { id: 'customer_id', label: 'Id', minWidth: 170 },
    { id: 'amount', label: 'Profit', minWidth: 100 },
    { id: 'reason', label: 'Reason', minWidth: 100 },
    { id: 'balance_on_date', label: 'Acc Bal', minWidth: 100 },
    { id: 'added_on', label: 'Date', minWidth: 100 },

  ];
  const transactionColumns = [

    { id: 'transaction_id', label: 'Id', minWidth: 170 },
    // { id: 'customer_id', label: 'Name', minWidth: 100 },
    { id: 'transaction_date', label: 'Date', minWidth: 100 },
    { id: 'amount', label: 'Amount', minWidth: 100 },
    { id: 'mode_of_pay', label: 'Payment type', minWidth: 100 },
    { id: 'type', label: 'TransactionType', minWidth: 100 },
  ];
  const dailyData = [
    { id: 'client_date', label: 'Date', minWidth: 170 },
    { id: 'customer_id', label: 'Customer Id', minWidth: 100 },
    { id: 'today_balance', label: 'Current Bal', minWidth: 100 },
    { id: 'current_profit', label: 'Profit', minWidth: 100 },
    { id: 'today_percent', label: 'Profit in(%)', minWidth: 100 },
  ];

  const removeUpdate = (response) => {
    setUpdateDetails(false)
    getUserData(userDetailsColums, apiReqId, false)
  }
  const getTable = (columnname, data) => {
    return <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 'auto' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columnname.map((column) => (
                  <TableCell
                    key={column.id}
                    // align={column.align}
                    //  style={{ minWidth: column.minWidth }}
                    sx={{
                      backgroundColor: 'yellowgreen',
                      border: '1px solid black',
                      borderRight: '0px',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columnname.map((column) => {
                        const value = column.id === 'transaction_date' || column.id === 'client_date'||column.id === 'added_on' ? moment(new Date(row[column.id])).format("DD/MM/YYYY") : row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              border: '1px solid rgba(224, 224, 224, 1)',
                            }}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={userData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  }
  return (
    <Grid container>
      <Snackbar
        open={createError}
        autoHideDuration={6000}
        onClose={() => setError(false)}
        message={errorMessaage}
      >
        <Alert
          onClose={() => setError(false)}
          severity={
            errorMessaage === 'No rows available!' ? 'success' : 'error'
          }
          sx={{ width: '100%' }}
        >
          {errorMessaage}
        </Alert>
      </Snackbar>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ pl: 10, pr: 10, pt: 5 }}>
        <ReactCardFlip isFlipped={fliped} flipDirection="horizontal">
          <Grid>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          // align={column.align}
                          //  style={{ minWidth: column.minWidth }}
                          sx={{
                            backgroundColor: 'yellowgreen',
                            border: '1px solid black',
                            borderRight: '0px',
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  sx={{
                                    border: '1px solid rgba(224, 224, 224, 1)',
                                  }}
                                >
                                  {value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={userData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>

          <Grid>
            <Grid container>
              <Grid item xs={6}>
                <button onClick={() => handleClick('toFrontFace', 0)} style={{ marginBottom: 5 }}>
                  <ArrowBackIcon />
                </button>
              </Grid>
             {userDetailsColums !== 'product_update'?<Grid item xs={6} display="flex" justifyContent={'end'}>
                <Button onClick={() => setUpdateDetails(!updateDetails)} style={{ marginBottom: 5 }} color='primary' variant='contained'>
                  {updateDetails ? 'Cancel Update' : 'Update'}
                </Button>
              </Grid>:''}
              <Grid item xs={12}>
                {userDetailsColums === 'product_update' ? <UpdateProduct productData={userpersonalData} userId={apiReqId} /> : getTable(userDetailsColums === "profits" ? profitColums : userDetailsColums === 'daily-datas' ? dailyData : transactionColumns, userpersonalData)}
              </Grid>
              <Grid item xs={12}>
                {updateDetails ? <UpdateData activity={userDetailsColums} datas={userpersonalData} removeUpdate={removeUpdate} userId={apiReqId}/> : ''}
              </Grid>
            </Grid>
          </Grid>
        </ReactCardFlip>
      </Grid>
    </Grid>
  );
}
