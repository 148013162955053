import { Alert, Button, Grid, MenuItem, Select, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import React, { useState, useEffect } from 'react'

export default function DailyDataUpdate(props) {
    const [createError, setError] = useState(false);
    const [errorMessaage, setErrorMessage] = useState(null);
    const [errorColor, setErrorColor] = useState('');
    const [id, setID] = useState()
    const [dailyData, setDailyData] = useState({ daily_data_id: 0, current_balance: 0, profit_earned: 0, percentage: 0 })

    const handleChange = (event, newValue) => {
        setID(event.target.value)
        props.datas.forEach(element => {
            if (element.id === event.target.value) {
                setDailyData({ daily_data_id: element.id, current_balance: element.today_balance, profit_earned: element.current_profit, percentage: element.today_percent })
            }
        });
    }
    const handleDailyDataChange = (e) => {
        var key = e.target.name;
        setDailyData({ ...dailyData, [key]: e.target.value })
    }
    const handleSubmit = () => {

        let token = localStorage.getItem('userToken')
        const confiq = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        let confirmUpdate = false;

        props.datas.forEach(element => {
            if (element.id === dailyData.daily_data_id) {

                if (dailyData.current_balance !== element.today_balance || dailyData.profit_earned !== element.current_profit || dailyData.percentage !== element.today_percent) {
                    confirmUpdate = true;
                }
            }
        });

        if (confirmUpdate) {
            axios.post(`${process.env.REACT_APP_API_URL}customers/update_daily_data`, dailyData, confiq)
                .then(function (response) {
                    setError(true)
                    setErrorColor('success')
                    setErrorMessage('Daily Data updated')
                   setTimeout(() => {
                    props.removeUpdate()
                   }, 3000);
                })
                .catch(function (error) {
                    setError(true)
                    setErrorColor('error')
                    setErrorMessage('Something went wrong')
                });
        } else {
            setError(true)
            setErrorColor('error')
            setErrorMessage('Already uptodate')
        }

    }
    return (
        <Grid container sx={{ mt: 2 }}>
            <Snackbar
                open={createError}
                autoHideDuration={6000}
                onClose={() => setError(false)}
                message={errorMessaage}
            >
                <Alert
                    onClose={() => setError(false)}
                    severity={
                       errorColor
                    }
                    sx={{ width: '100%' }}
                >
                    {errorMessaage}
                </Alert>
            </Snackbar>
            <Grid item xs={12}>
                <label>Select Date<br /><br /></label>
                <Select
                    sx={{ minWidth: 300 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={id}
                    onChange={handleChange}
                >
                    {props.datas.map((data) => (
                        <MenuItem value={data.id}>{moment(new Date(data.client_date)).format("DD/MM/YYYY")}</MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={3} sx={{ mt: 2 }}>
                <TextField id="current_balance" value={dailyData.current_balance} onChange={(e) => handleDailyDataChange(e)} name="current_balance" label="Current Balance" variant="outlined" />
            </Grid>
            <Grid item xs={3} sx={{ mt: 2 }}>
                <TextField id="profit_earned" name="profit_earned" value={dailyData.profit_earned} onChange={(e) => handleDailyDataChange(e)} label="Profit" variant="outlined" />
            </Grid>
            <Grid item xs={3} sx={{ mt: 2 }}>
                <TextField id="percentage" name="percentage" value={dailyData.percentage} onChange={(e) => handleDailyDataChange(e)} label="Profit %" variant="outlined" />
            </Grid>
            <Grid item xs={3} sx={{ mt: 2 }}></Grid>
            <Grid item xs={9} sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Button variant='contained' onClick={() => handleSubmit()}>Save</Button>
            </Grid>
            <Grid item xs={9} sx={{ mt: 2 }}></Grid>
        </Grid>
    )
}
