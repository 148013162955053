import {
	Alert,
	Box,
	Button,
	Grid,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import * as yup from 'yup';
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { isMobile } from 'react-device-detect';
import {
	inverstmentDroped,
	inverstmentGoals,
	profitType,
	riskRewards,
} from './Utils';
import axios from 'axios';
import isEmailValidator from 'validator';
import { options } from 'joi';
export default function CreateUser() {
	const [dob, setDOB] = useState(null);
	const [dropdowndatas, setDropdownValues] = useState({});
	const [newMemberValue, setNewMemberValue] = useState({});
	const [createError, setError] = useState(false);
	const [errorMessaage, setErrorMessage] = useState(null);
	const [disablesubmitButton, setDisablesubmitButton] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const [remainingAllocation, setRemainingAllocation] = useState(100);
	const [allocationData, setallocationData] = useState({ sgsAllocation: 0, tgdAllocation: 0 });
	const [remainingSGSValue, setRemainingSGSValue] = useState(100);
	const [sgsData, setSGSData] = useState({ USDC: 0, DAI: 0, USDT: 0, UST: 0 });
	const [remainingTGDValue, setRemainingTGDValue] = useState(100);
	const [tgdData, setTGData] = useState({ One: 0, FTM: 0, SOL: 0, AVAX: 0, CRO: 0, ETH: 0, BTC: 0, other: 0 });

	const schema = yup.object().shape({
		// Imported Email validator to allow emails with "."
		Email: yup
			.string()
			.required('Email is required')
			.email('Must be a valid email'),
		password: yup
			.string()
			.matches(
				new RegExp(
					'^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
				),
				'Password should be 8 characters inclusive of uppercase, lowercase, special characters'
			)
			.required('password is required'),
		dob: yup
			.date('Date is required')
			.required('Date is required')
			.typeError('Date is required'),
		mobile: yup
			.string()
			.min(8, 'mobile number should be at least 8 digits long')
			.required('mobile number is required'),
		first_name: yup.string().max(255).required('First name is required'),
		last_name: yup.string().max(255).required('Last name is required'),
		user_name: yup.string().max(255).required('User name is required'),
	});
	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		// eslint-disable-next-line no-console

		const regdata = {
			data: {
				Email: data.get('Email'),
				password: data.get('password'),
				dob: data.get('dob'),
				mobile: data.get('mobile'),
				first_name: data.get('first_name'),
				last_name: data.get('last_name'),
				// Investment_goals: dropdowndatas.Investment_goals,
				// investment_dropped: dropdowndatas.investment_dropped,
				// risk_rewards: dropdowndatas.risk_rewards,
				// profit_type: dropdowndatas.profit_type,
				user_name: data.get('user_name'),
				product_details: [
					{
						name: "Stable Growth Staking",
						yield: data.get('sgs-yield') ? parseFloat(data.get('sgs-yield')) : 0,
						allocation: data.get('sgsAllocation') ? parseFloat(data.get('sgsAllocation')) : 0,
						assetBreakdown: [
							{ name: "USDC", amount: data.get('USDC') ? parseFloat(data.get('USDC')) : 0 },
							{ name: "DAI", amount: data.get('DAI') ? parseFloat(data.get('DAI')) : 0 },
							{ name: "USDT", amount: data.get('USDT') ? parseFloat(data.get('USDT')) : 0 },
							{ name: "UST", amount: data.get('UST') ? parseFloat(data.get('UST')) : 0 },
						]
					},
					{
						name: "The Great Dynamic",
						yield: data.get('tgd-yield') ? parseFloat(data.get('tgd-yield')) : 0,
						allocation: data.get('tgdAllocation') ? parseFloat(data.get('tgdAllocation')) : 0,
						assetBreakdown: [
							{ name: "One", amount: data.get('One') ? parseFloat(data.get('One')) : 0 },
							{ name: "FTM", amount: data.get('FTM') ? parseFloat(data.get('FTM')) : 0 },
							{ name: "SOL", amount: data.get('SOL') ? parseFloat(data.get('SOL')) : 0 },
							{ name: "AVAX", amount: data.get('AVAX') ? parseFloat(data.get('AVAX')) : 0 },
							{ name: "CRO", amount: data.get('CRO') ? parseFloat(data.get('CRO')) : 0 },
							{ name: "ETH", amount: data.get('ETH') ? parseFloat(data.get('ETH')) : 0 },
							{ name: "BTC", amount: data.get('BTC') ? parseFloat(data.get('BTC')) : 0 },
							{ name: "other", amount: data.get('other') ? parseFloat(data.get('other')) : 0 }

						]
					},
					{

						name: "The Alt Risk Rewards",
						yield: 0.0,
						allocation: 0.0,
						assetBreakdown: []
					}

				]
			},
		};
		try {
			const value = await schema.validate(regdata.data);
			console.log(value);
		} catch (error) {
			console.log(error.errors);
			setError(true);
			setErrorMessage(error.errors[0]);
			return;
		}


		let token = localStorage.getItem('userToken');
		const confiq = {
			headers: {
				Authorization: `Bearer ${token} `,
			},
		};
		setDisablesubmitButton(true);
		setDisableButton(true);
		axios
			.post(`${process.env.REACT_APP_API_URL}customers`, regdata, confiq)
			.then(function (response) {
				if (response.data) {
					setDisablesubmitButton(false);
					setError(true);
					setErrorMessage('Member Added Successfully');
					document.forms['createForm'].reset();
					setDropdownValues({});
				}
			})
			.catch(function (error) {
				console.log(error.response);
				if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.mobileNumErr
				) {
					setDisablesubmitButton(false);
					setDisableButton(false);
					setError(true);
					setErrorMessage(error.response.data.mobileNumErr);
				} else {
					setDisablesubmitButton(false);
					setDisableButton(false);
					setError(true);
					setErrorMessage('Something Went Wrong');
				}
			});
		console.log('password invalid');
	};

	const handleDropDownChange = (e) => {
		const key = e.target.name;
		setDropdownValues({ ...dropdowndatas, [key]: e.target.value });
	};
	const calculateAllocation = async (e) => {
		let data = e.target.value > 0 ? parseInt(e.target.value) : 0;
		if (data === 0) {
			setallocationData({ ...allocationData, [e.target.name]: 0 })
			let remainVal = 0;
			Object.entries(allocationData).map(item => {
				if (item[0] === e.target.name) {
					console.log(item[0] === e.target.name)
				} else {
					remainVal += parseInt(item[1])
				}
			})
			setRemainingAllocation(100 - remainVal)
			setDisableButton(false);
		}
		const key = e.target.name;
		let newValue = parseInt(allocationData.tgdAllocation) + parseInt(allocationData.sgsAllocation) + data;
		let finalvalue = newValue - parseInt(allocationData[key]);
		let RemainingValue = 100 - finalvalue;
		if (data != 0 && finalvalue > 0 && finalvalue <= 100) {
			setallocationData({ ...allocationData, [e.target.name]: parseInt(e.target.value) })
			setRemainingAllocation(RemainingValue)
			setDisableButton(false);

		} else if (data != 0) {
			setRemainingAllocation('Allocation Reached')
			setDisableButton(true);
		}
	};

	const calculateSGS = (e) => {
		const key = e.target.name;
		let data = e.target.value > 0 ? parseInt(e.target.value) : 0;
		if (data === 0) {
			setSGSData({ ...sgsData, [e.target.name]: 0 })
			let remainVal = 0;
			Object.entries(sgsData).map(item => {
				if (item[0] === e.target.name) {
					console.log(item[0] === e.target.name)
				} else {
					remainVal += parseInt(item[1])
				}
			})
			setRemainingSGSValue(100 - remainVal)
			setDisableButton(false);
		}
		let newValue = parseInt(sgsData.DAI) + parseInt(sgsData.USDC) + parseInt(sgsData.USDT) + parseInt(sgsData.UST) + data;
		let finalValue = newValue - parseInt(sgsData[key]);
		let RemainingValue = 100 - finalValue;
		if (data != 0 && finalValue > 0 && finalValue <= 100) {
			setSGSData({ ...sgsData, [e.target.name]: parseInt(e.target.value) })
			setRemainingSGSValue(RemainingValue);
			setDisableButton(false);
		} else if (data != 0) {
			setRemainingSGSValue('Allocation Reached');
			setDisableButton(true);
		}
	};

	const calculateTGD = (e) => {
		let data = e.target.value > 0 ? parseInt(e.target.value) : 0;
		if (data === 0) {
			setTGData({ ...tgdData, [e.target.name]: 0 })
			let remainVal = 0
			Object.entries(tgdData).map(item => {
				if (item[0] === e.target.name) {
					console.log(item[0] === e.target.name)
				} else {
					remainVal += parseInt(item[1])
				}
			})
			setRemainingTGDValue(100 - remainVal)
			setDisableButton(false);
		}
		const key = e.target.name;
		let newValue = parseInt(tgdData.AVAX) + parseInt(tgdData.BTC) + parseInt(tgdData.CRO) + parseInt(tgdData.ETH) + parseInt(tgdData.FTM) + parseInt(tgdData.One) + parseInt(tgdData.SOL) + parseInt(tgdData.other) + data;
		let finalValue = newValue - parseInt(tgdData[key]);
		let RemainingValue = 100 - finalValue;
		if (data != 0 && finalValue > 0 && finalValue <= 100) {
			setTGData({ ...tgdData, [e.target.name]: parseInt(e.target.value) })
			setRemainingTGDValue(RemainingValue)
			setDisableButton(false);
		} else if (data != 0) {
			setRemainingTGDValue('Allocation Reached')
			setDisableButton(true);
		}
	};
	return (
		<Grid
			container
			component='main'
			sx={
				!isMobile
					? { height: '100vh', pl: 20, pr: 20, pt: 5, pb: 5, width: '100%' }
					: { height: '100vh' }
			}
		>
			<Snackbar
				open={createError}
				autoHideDuration={6000}
				onClose={() => setError(false)}
				message={errorMessaage}
			>
				<Alert
					onClose={() => setError(false)}
					severity={
						errorMessaage === 'Member Added Successfully' ? 'success' : 'error'
					}
					sx={{ width: '100%' }}
				>
					{errorMessaage}
				</Alert>
			</Snackbar>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				display='flex'
				justifyContent='center'
				component={Paper}
				elevation={6}
				square
			>
				<Box
					sx={{
						// my: 6,
						mx: 2,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Grid
						container
						component='form'
						name='createForm'
						noValidate
						onSubmit={handleSubmit}

					>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							sx={{ p: 1 }}
							justifyItems='center'
						>
							<Typography variant='h5'>Create New member</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
							<TextField
								margin='normal'
								required
								fullWidth
								id='first_name'
								label='First Name'
								name='first_name'
								autoComplete='off'
								type='text'
								disabled={disablesubmitButton}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
							<TextField
								margin='normal'
								required
								fullWidth
								name='last_name'
								label='Last Name'
								type='text'
								id='last_name'
								autoComplete='off'
								disabled={disablesubmitButton}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
							<TextField
								margin='normal'
								required
								fullWidth
								name='user_name'
								label='User Name'
								type='text'
								id='user_name'
								autoComplete='off'
								disabled={disablesubmitButton}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
							<TextField
								margin='normal'
								required
								fullWidth
								id='Email'
								label='Email Address'
								name='Email'
								autoComplete='off'
								disabled={disablesubmitButton}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
							<TextField
								margin='normal'
								required
								fullWidth
								name='mobile'
								label='Mobile No'
								type='number'
								id='mobile'
								autoComplete='off'
								disabled={disablesubmitButton}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
							<TextField
								margin='normal'
								required
								fullWidth
								name='password'
								label='Password'
								type='password'
								id='password'
								autoComplete='off'
								disabled={disablesubmitButton}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
							<TextField
								InputLabelProps={{ shrink: true }}
								margin='normal'
								required
								fullWidth
								name='dob'
								label='Date of Birth'
								type='date'
								id='dob'
								autoComplete='off'
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}></Grid>
						<Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}></Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							sx={{ p: 1 }}
							justifyItems='center'
						>
							<Typography variant='h5'>Products</Typography>
						</Grid>
						<Grid container style={{ border: '1px solid grey' }}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								sx={{ p: 1 }}
								justifyItems='center'
							>
								<Typography variant='p'>Stable Growth Staking</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
								<TextField
									margin='normal'
									required
									fullWidth
									id='sgs-yield'
									label='Yield'
									name='sgs-yield'
									autoComplete='off'
									disabled={disablesubmitButton}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='sgsAllocation'
									label='Allocation'
									name='sgsAllocation'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateAllocation(e)}
								/>
								<Typography>Allocation:{remainingAllocation}</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								sx={{ p: 1 }}
								justifyItems='center'
							>
								<Typography variant='p'>Asset Breakdown</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='USDC'
									label='USDC'
									name='USDC'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateSGS(e)}
								/>
								<Typography>Remaining:{remainingSGSValue}</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='DAI'
									label='DAI'
									name='DAI'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateSGS(e)}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='USDT'
									label='USDT'
									name='USDT'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateSGS(e)}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='UST'
									label='UST'
									name='UST'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateSGS(e)}
								/>
							</Grid>
						</Grid>
						<Grid container sx={{ border: '1px solid grey', mt: 2 }}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								sx={{ p: 1, }}
								justifyItems='center'
							>
								<Typography variant='p'>The Great Dynamic</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
								<TextField
									margin='normal'
									required
									fullWidth
									id='tgd-yield'
									label='Yield'
									name='tgd-yield'
									autoComplete='off'
									disabled={disablesubmitButton}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='tgdAllocation'
									label='Allocation'
									name='tgdAllocation'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateAllocation(e)}
								/>
								<Typography>Allocation:{remainingAllocation}</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								sx={{ p: 1 }}
								justifyItems='center'
							>
								<Typography variant='p'>Asset Breakdown</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='One'
									label='One'
									name='One'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateTGD(e)}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='FTM'
									label='FTM'
									name='FTM'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateTGD(e)}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='SOL'
									label='SOL'
									name='SOL'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateTGD(e)}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='AVAX'
									label='AVAX'
									name='AVAX'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateTGD(e)}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='CRO'
									label='CRO'
									name='CRO'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateTGD(e)}
								/>
								<Typography>Remaining:{remainingTGDValue}</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='ETH'
									label='ETH'
									name='ETH'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateTGD(e)}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='BTC'
									label='BTC'
									name='BTC'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateTGD(e)}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
								<TextField
									type='number'
									inputProps={{
										max: 100,
										min: 0
									}}
									margin='normal'
									required
									fullWidth
									id='other'
									label='other'
									name='other'
									autoComplete='off'
									disabled={disablesubmitButton}
									onChange={(e) => calculateTGD(e)}
								/>
							</Grid>

						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}></Grid>
						<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
							<Button
								type='reset'
								fullWidth
								variant='outlined'
								sx={{ mt: 3, mb: 2 }}
								disabled={disablesubmitButton}
								onClick={() => setDropdownValues({})}
							>
								Clear
							</Button>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
							<Button
								type='submit'
								fullWidth
								variant='contained'
								sx={{ mt: 3, mb: 2 }}
								disabled={disableButton}
							>
								Create
							</Button>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}></Grid>
					</Grid>
				</Box>
			</Grid>
		</Grid>
	);
}
